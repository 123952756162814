import React, { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars4Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { LanguageIcon, UserIcon } from '@heroicons/react/24/solid';
import { Text, Link } from './';
import { userActions } from '../_actions';
import { connect } from 'react-redux';
import { history } from '../_library';


const Navbar = props => {

    const navigation = ['Projects'];
    const profile = [{label: 'Profile', onClick: () =>   history.push('/profile')}, 
    {label: 'Sign out', onClick: () => props.logout()}];

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    return(
        <Disclosure as="nav" className="bg-blue-500 shadow-md">
            {({ open }) => (
                <>
                    <div className="mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between h-16">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center">
                                        <LanguageIcon className="w-10 text-amber-400" />
                                        <Text label="mcrn.brand" className="ml-3 mt-1 text-3xl text-white" />
                                        <Text label="mcrn.i18n.brand" className="ml-2 mt-1 text-3xl mr-1 font-bold text-white" />
                                    </div>
                                    <Text center label="mcrn.i18n.brand.description" className="text-xs text-white" />
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-10 flex items-baseline space-x-4">
                                        {navigation.map((item, itemIdx) =>
                                            itemIdx === 0 ? (
                                                <Fragment key={item}>
                                                    <Link to='/' className="bg-indigo-100 text-white px-3 py-2 rounded-md text-sm font-medium">
                                                        {item}
                                                    </Link>
                                                </Fragment>
                                            ) : (
                                                <a
                                                    key={item}
                                                    href="#"
                                                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                                >
                                                    {item}
                                                </a>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-4 flex items-center md:ml-6">
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative">
                                        <div className="flex items-center">
                                            <div className="mr-3">
                                                <div className="text-base font-medium leading-none text-white">{props.user.name}&nbsp;{props.user.surname}</div>
                                            </div>
                                            <Menu.Button className="max-w-xs rounded-full bg-gray-100 flex items-center text-sm focus:outline-none p-1">
                                                <span className="sr-only">Open user menu</span>
                                                <UserIcon className="w-6 text-gray-400" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                                                {profile.map((item, idx) => (
                                                    <Menu.Item key={idx}>
                                                        {({ active }) => (
                                                            <div
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                                )}
                                                                onClick={(item.onClick ? () => item.onClick() : () => {})}
                                                                
                                                            >
                                                                {item.label}
                                                            </div>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                            <div className="-mr-2 flex md:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars4Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                            {navigation.map((item, itemIdx) =>
                                itemIdx === 0 ? (
                                    <Fragment key={item}>
                                        {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                                        <a href="#" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
                                            {item}
                                        </a>
                                    </Fragment>
                                ) : (
                                    <a
                                        key={item}
                                        href="#"
                                        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        {item}
                                    </a>
                                )
                            )}
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-700">
                            <div className="flex items-center px-5">
                                <div className="flex-shrink-0">
                                    <div className="rounded-full bg-gray-100 p-1">
                                        <UserIcon className="w-6 text-gray-400" />
                                    </div>
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium leading-none text-white">{props.user.name}&nbsp;{props.user.surname}</div>
                                    <div className="text-sm font-medium leading-none text-gray-700">{props.user.email}</div>
                                </div>
                            </div>
                            <div className="mt-3 px-2 space-y-1">
                                {profile.map((item, idx) => (
                                    <div key={idx}
                                        className="cursor-pointer block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:text-white hover:bg-gray-900"
                                        onClick={(item.onClick ? () => item.onClick() : () => {})}>
                                        {item.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );

};

function mapStateToProps(state) {
    const { user } = state;
    return {
        user
    };
}

function mapDispatchToProps(dispatch) {
    return({
        logout: () => {
            dispatch(userActions.logout())
        }
    })
}

const connectedNavbar = connect(mapStateToProps, mapDispatchToProps)(Navbar);
export { connectedNavbar as Navbar };

