import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from './';
import PropTypes from 'prop-types';


export const Table = props => {
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {props.columns.map((column, idx) => (
                                        <th key={idx} scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <FormattedMessage id={column.label} />
                                        </th>
                                    )
                                    )}
                                    {props.action &&
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    }
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {props.rows.map((row, idx) => (
                                    <tr key={idx}>
                                        {props.columns.map((column, columnIdx) => {
                                            switch (column.type) {
                                                case 'rows':
                                                    return (
                                                        <td key={idx + '_' + columnIdx} className="px-6 py-4 whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <div className="ml-4">
                                                                    {column.rows.map((columnRow, columnRowIdx) => (
                                                                        <div key={idx + '_' + columnRowIdx}
                                                                            className={'text-sm ' + (columnRow.gray ? 'text-gray-500' : 'font-medium text-gray-900')}>
                                                                            {row[columnRow.name]}
                                                                        </div>
                                                                    )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    );
                                                case 'status':
                                                    return (
                                                        <td key={idx + '_' + columnIdx} className="px-6 py-4 whitespace-nowrap">
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                                <FormattedMessage id={props.statuses[row[column.name]]} />
                                                            </span>
                                                        </td>
                                                    );
                                                case 'role':
                                                    return (
                                                        <td key={idx + '_' + columnIdx} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            <FormattedMessage id={props.roles[row[column.name]]} />
                                                        </td>
                                                    );
                                                case 'custom_render':
                                                    return (
                                                        <td key={idx + '_' + columnIdx} className="px-6 py-4 whitespace-nowrap">
                                                            {column.customRender(row)}
                                                        </td>
                                                    );
                                                default:
                                                    return (
                                                        <td key={idx + '_' + columnIdx} className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                            {row[column.name]}
                                                        </td>
                                                    );
                                            }
                                        }
                                        )}
                                        {props.action && (!props.action.actionAllowedFor || (props.action.actionAllowedFor && props.action.rolesMapper[props.action.actionAllowedFor] >= props.action.rolesMapper[row.role])) ?
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <Link onClick={() => {
                                                    if (props.action.passDataToOnClick) {
                                                        props.action.onClick(row[props.action.passDataToOnClick]);
                                                    } else if (props.action.passRowOnClick) {
                                                        props.action.onClick(row);
                                                    } else {
                                                        props.action.onClick();
                                                    }
                                                }} label={props.action.label} />
                                            </td> : <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};


Table.protoTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    statuses: PropTypes.object,
    accessLevels: PropTypes.object,
    roles: PropTypes.object,
    action: PropTypes.object,
    actionAllowedFor: PropTypes.any,
    rolesMapper: PropTypes.any
};
