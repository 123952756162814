import localeDataEn from '../translations/en.json';
import { i18nConstants } from '../_constants';
import config from '../config';


// const language = (navigator.languages && navigator.languages[0]) ||
//     navigator.language ||
//     navigator.userLanguage;

// const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

const initialState = {
    lang: 'en',
    // messages: localeData[languageWithoutRegionCode] || localeData[language] || localeData.en,
    messages: localeDataEn,
    synchronized: false
};

const supportedLanguages = ['EN'];

export function i18n(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case i18nConstants.CHANGE_LANGUAGE:
            if (action.data.lang === null || action.data.lang === undefined || !supportedLanguages.includes(action.data.lang.toString().toUpperCase())) {
                state.lang = 'en';
            } else {
                state.lang = action.data.lang;
            }
            localStorage.setItem(config.language, state.lang);

            switch (action.data.lang) {
                case 'en':
                    state.messages = localeDataEn;
                    break;
                default:
                    break;
            }

            // state.messages = localeData[action.data.lang];
            return Object.assign({}, state);
        default:
            return state;
    }
}
