import React from 'react';
import { Formik, Form as FForm } from 'formik';
import { Button, FormInput, FormSelect } from './';
import PropTypes from 'prop-types';
import * as Yup from 'yup';


export const Form = props => {

    return(
        <Formik
            enableReinitialize={props.enableReinitialize}
            initialValues={props.initValues}
            validationSchema={Yup.object().shape(props.validationSchema)}
            onSubmit={props.onSubmit}
        >
            {({ isSubmitting, dirty, isValid }) => (
                <FForm autoComplete="off" className={'space-y-6 ' + (props.className || '')}>
                    <div className="space-y-3">
                        {props.fields.map((field, idx) => {
                                switch (field.type) {
                                    case 'select':
                                        return <FormSelect key={idx} {...field} />;
                                    default:
                                        return <FormInput key={idx} {...field} disabled={(!field.disabled ? props.disabled : field.disabled)} />;
                                }
                            }
                        )}
                    </div>
                    <div className={'flex space-x-5 ' + (props.cancelBtn ? 'justify-between' : '')}>
                        <Button type="submit" icon={props.submitBtnIcon} label={props.submitBtnLabel}
                                disabled={isSubmitting || !dirty || !isValid} className={(props.submitBtnFullWidth ? 'w-full' : '')} />
                        {props.cancelBtn &&
                            <Button label={props.cancelBtnLabel} onClick={props.cancelBtn}
                                    disabled={isSubmitting} secondary />
                        }
                    </div>
                </FForm>
            )}
        </Formik>
    );

};

Form.propTypes = {
    disabled: PropTypes.bool,
    initValues: PropTypes.object.isRequired,
    validationSchema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitBtnIcon: PropTypes.string,
    submitBtnLabel: PropTypes.string.isRequired,
    enableReinitialize: PropTypes.bool,
    className: PropTypes.string,
    submitBtnFullWidth: PropTypes.bool,
    cancelBtn: PropTypes.func,
    cancelBtnLabel: PropTypes.string
};
