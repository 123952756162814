import React  from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useField } from 'formik';
import { Text } from './Text';


export const FormInput = props => {

    const intl = useIntl();
    const [field, meta] = useField(props.name);
    const Tag = props.tag || 'input';

    return(
        <div className={'' + (props.className || '')}>
            {props.label &&
                <label htmlFor={props.name} className="block text-sm text-left font-medium text-gray-700">
                    <FormattedMessage id={props.label} />
                </label>
            }
            <Tag
                {...field}
                {...(props.textareaRows ? {rows: props.textareaRows} : {})}
                type={props.type ? props.type : 'text'}
                name={props.name}
                id={props.name}
                disabled={props.disabled}
                placeholder={intl.formatMessage({id: props.placeholder})}
                className={'rounded-md shadow-sm mt-1 focus:ring-sky-600 focus:border-sky-500 block ' +
                'w-full shadow-sm sm:text-sm border-gray-300 rounded-md ' + (meta.touched && meta.error && 'border-red-500 focus:border-red-600 focus:ring-red-600')}
            />
            {meta.touched && meta.error &&
                <Text left small className="text-red-500 sm:text-sm">{meta.error}</Text>
            }
        </div>
    );
};

FormInput.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    tag: PropTypes.string
};
