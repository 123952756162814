import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useField } from 'formik';
import { Text } from './';
import React from "react";


export const FormSelect = props => {

    const intl = useIntl();
    const [field, meta] = useField(props.name);

    return(
        <div className={'' + (props.className || '')}>
            {props.label &&
                <label htmlFor={props.name} className="block text-sm text-left font-medium text-gray-700">
                    <FormattedMessage id={props.label} />
                </label>
            }
            <select
                {...field}
                id={props.name}
                name={props.name}
                {...(props.onChange ? {onChange: e => props.onChange(e.target.value)} : {})}
                placeholder={(props.showLabel ? intl.formatMessage({id: props.placeholder}) : '')}
                className={'rounded-md shadow-sm focus:ring-sky-600 focus:border-sky-500 block text-black ' + ((meta.value === '' && 'text-gray-400 ') || '') +
                    'w-full shadow-sm sm:text-sm border-gray-300 ' + (meta.touched && meta.error && 'border-red-500 focus:border-red-600 focus:ring-red-600')}
            >
                {/*<option selected={props.selected ? 'selected': ''} value="" defaultValue hidden>{intl.formatMessage({id: props.placeholder})}</option>*/}
                {props.options.map((el, idx) => (
                        <>
                            {props.notLabels === true &&
                                <option selected={props.selected ? 'selected': ''} value={el.value} key={el.value + idx} className={'text-black'}>
                                    {el[props.keyName]}
                                </option>
                            }
                            {!props.notLabels &&
                                <option selected={props.selected ? 'selected': ''} value={el.value} key={el.value + idx} className={'text-black'}>
                                    {intl.formatMessage({id: el.display})}
                                </option>
                            }
                        </>
                    )
                )}
            </select>
            {meta.touched && meta.error &&
                <Text left small className="text-red-500 sm:text-sm">{meta.error}</Text>
            }
        </div>
    );
};

FormSelect.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        display: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    label: PropTypes.string,
    className: PropTypes.string
};
