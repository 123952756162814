import { userConstants } from '../_constants';


const initialState = {
    loggedIn: false,
    synchronized: false,
    email: '',
    name: '',
    surname: '',
    role: 'guest',
    status: 'created'
};

export function user(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case userConstants.LOAD_SETTINGS_SUCCESS:
            state.loggedIn = true;
            state.synchronized = true;
            return Object.assign({}, {...state, ...action.data});
        case userConstants.LOAD_SETTINGS_FAILURE:
            return Object.assign({...state}, action.data);
        case userConstants.RESET_SETTINGS:
            return Object.assign({}, {...initialState, synchronized: true});
        default:
            return state;
    }
}
