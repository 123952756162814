import React from 'react';
import { FormattedMessage } from 'react-intl';


export const Text = props => {

    let Tag = 'span';

    if (props.left || props.right || props.justify || props.center || props.fullWidth) {
        Tag = 'div';
    }

    let classes = '';

    if (props.left) classes += 'text-left ';
    if (props.right) classes += 'text-right ';
    if (props.justify) classes += 'text-justify ';
    if (props.center) classes += 'text-center ';

    return (
        <Tag className={classes + (props.className || '')}>
            {props.label &&
                <FormattedMessage id={props.label} values={(props.labelValues ? props.labelValues : {})} />
            }
            {props.children &&
                <>{props.children}</>
            }
        </Tag>
    );
};
