import React from 'react';
import PropTypes from 'prop-types';


export const Avatar = props => (
    <span className={'rounded rounded-full h-7 flex items-center justify-center p-1 ml-1 -mt-1 text-white bg-indigo-500 text-sm ' +
        (props.notCircle ? 'px-3' : 'w-7')}>
        {props.children}
    </span>
);

Avatar.propTypes = {
    notCircle: PropTypes.bool
};
