import React from 'react';
import { history } from '../_library';
import { Button, Text } from './';


export const Error = props => {

    return (
        <div className="text-center position-relative">
            <Text center label={props.mainLabel} className="h3 mt-3" />
            {props.secondLabel &&
                <Text center label={props.secondLabel} className="mt-1" />
            }
            {!props.disableButton &&
                <Button label={props.buttonLabel} className="mt-3 max-w-xs mx-auto" onClick={() => {history.push(props.link); history.go();}} />
            }
        </div>
    );
};
