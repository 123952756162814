import { userConstants } from '../_constants';
import { api, history, openWSConnection } from '../_library';
import config from '../config';
import { i18nActions } from './i18n.actions';


export const userActions = {
    loadSettings,
    login,
    logout
};

function loadSettings() {
    return dispatch => {

        const lang = localStorage.getItem(config.language);

        dispatch(i18nActions.changeLanguage(lang))

        api.get('/user/profile')
            .then(res => {
                dispatch(success(res.data));
                openWSConnection();
            }).catch(() => {
                localStorage.removeItem(config.accessTokenName);
                dispatch(reset());
                dispatch(success({}));
            });
    };

    function success(data) { return { type: userConstants.LOAD_SETTINGS_SUCCESS, data } }
    function reset() { return { type: userConstants.RESET_SETTINGS } }
}

function login() {
    return dispatch => {
        api.get('/user/profile')
            .then(res => {
                dispatch(success(res.data));
                history.push('/dashboard');
            }).catch(error => {
                dispatch(logout());
                dispatch(failure(error));
        });
    };

    function success(data) { return { type: userConstants.LOAD_SETTINGS_SUCCESS, data } }
    function failure(error) { return { type: userConstants.LOAD_SETTINGS_FAILURE, error } }
}

function logout() {
    return dispatch => {

        api.post('/logout')
            .then(() => {
                localStorage.removeItem(config.accessTokenName);
                dispatch(success());
                history.push('/');
            })
            .catch(() => {
                localStorage.removeItem(config.accessTokenName);
                dispatch(success());
                history.push('/');
            });
    };

    function success() { return { type: userConstants.RESET_SETTINGS } }
}
