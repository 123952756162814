import axios from 'axios';
import config from '../config';

export const api = axios.create({
    baseURL: config.baseUrl
});

api.interceptors.request.use(function (axiosConfig) {
    if (localStorage.getItem(config.accessTokenName)) {
        axiosConfig.headers.Authorization = 'Bearer ' + localStorage.getItem(config.accessTokenName);
    }

    if (localStorage.getItem(config.language)) {
        axiosConfig.headers['Accept-Language'] = localStorage.getItem(config.language);
    } else {
        axiosConfig.headers['Accept-Language'] = 'en';
    }

    return axiosConfig;
});

