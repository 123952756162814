import React from 'react';


export const DataLoader = () => (
    <div className="animate-pulse flex space-x-4 mb-3">
        <div className="flex-1 space-y-2 py-1">
            <div className="h-2 bg-blue-400 rounded w-2/4" />
            <div className="space-y-2">
                <div className="h-2 bg-blue-400 rounded w-3/4" />
                <div className="h-2 bg-blue-400 rounded w-2/6" />
            </div>
        </div>
    </div>
);

