let config = {
    baseUrl:'https://api.i18n.micron.lv/v1', //MEMO: michael: prod
    wsBaseUrl: 'wss://api.i18n.micron.lv', //MEMO: michael: local
    // baseUrl: 'http://127.0.0.1:5001/v1', //MEMO: michael: local
    // wsBaseUrl: 'ws://127.0.0.1:5001', //MEMO: michael: local
    accessTokenName: 'access_token',
    userRoles: {
        'guest': 0,
        'user': 1
    },
    userStatus: {
        'created': 0,
        'confirmed': 1,
        'blocked': 2,
        'removed': 3
    },
    supportedLangs: ['en'],
    language: 'language',
    projectUserRoles: {
        owner: 'owner', //can anything
        admin: 'admin', //user from business, can add developers, translators and admins
        developer: 'developer', //has developer functionality (like add, export labels)
        translator: 'translator', //can only translate, nothing more
    }
};

export default Object.freeze(Object.assign({}, config));
