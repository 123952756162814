import config from '../config';


export let socket = null;


export const openWSConnection = async (callback = false) => {

    if (socket && callback) {
      callback(socket);
    } else if (!socket) {

        const accessToken = await localStorage.getItem(config.accessTokenName);

        if (accessToken) {
            socket = await new WebSocket(config.wsBaseUrl + '/' + accessToken);
        }
    }

    socket.addEventListener('open', (event) => {
        if (callback) {
            callback(socket);
        }
    });

    socket.onclose = () => {
        socket = null;
        openWSConnection(callback);
    };
};
