import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const VerticalTabs = props => {
    const [tabId, setTabId] = useState(0);

    useEffect(() => {
        if (props.selected !== undefined) {
            const idx = props.tabs.findIndex(i => i.key === props.selected);

            if (idx > -1) {
                setTabId(idx);
            }
        }
    }, []);

    return (
        <div className="grid grid-cols-3 flex flex-row">
            <div className="h-screen w-44 bg-gray-300 shadow-md fixed">
                <ul className="p-0 h-auto pt-5">
                    {props.tabs.map((val, index) => {
                        return (
                            <div key={index}>
                                <div className="ml-3 mr-3">
                                    <li className={'w-auto h-10 m-0 flex flex-row text-gray-800 items-center ' +
                                        (!val.disabled ? 'hover:font-bold hover:text-indigo-600 cursor-pointer ' : 'cursor-pointer ') +
                                        (index === tabId && !val.disabled ? 'text-indigo-600 font-bold' : '')}
                                        onClick={() => !val.disabled && setTabId(index)}>
                                        <div className="ml-5">{val.title}</div>
                                    </li>
                                </div>
                            </div>
                        );
                    })}
                </ul>
            </div>
            <div className="font-bold tracking-wide ml-48">
                {props.tabs[tabId].content}
            </div>
        </div>
    );
}

VerticalTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.string,
};
