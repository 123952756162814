import React, { useEffect, Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { LoadingWithSuspense, Error, PrivateRoute } from './components';
import { connect } from 'react-redux';
import { userActions } from './_actions/';
import { history } from './_library';
import config from './config';
const PageNotFound = React.lazy(() => import(/* webpackChunkName: "page_not_found" */ './screens/PageNotFound').then(module => ({ default: module.PageNotFound })));
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ './screens/Login').then(module => ({ default: module.Login })));
const Dashboard = React.lazy(() => import(/* webpackChunkName: "dashboard" */ './screens/Dashboard').then(module => ({ default: module.Dashboard })));
const Profile = React.lazy(() => import(/* webpackChunkName: "profile" */ './screens/Profile/Profile').then(module => ({ default: module.Profile })));
const Signup = React.lazy(() => import(/* webpackChunkName: "signup" */ './screens/Signup').then(module => ({ default: module.Signup })));
const Labels = React.lazy(() => import(/* webpackChunkName: "labels" */ './screens/Labels').then(module => ({ default: module.Labels })));
const EmailConfirmation = React.lazy(() => import(/* webpackChunkName: "email_confirmation" */ './screens/EmailConfirmation').then(module => ({ default: module.EmailConfirmation })));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "reset_password" */ './screens/ResetPassword').then(module => ({ default: module.ResetPassword })));
const ResetPasswordRequest = React.lazy(() => import(/* webpackChunkName: "reset_password_request" */ './screens/ResetPasswordRequest').then(module => ({ default: module.ResetPasswordRequest })));
const ProjectUserEmailConfirmation = React.lazy(() => import(/* webpackChunkName: "project_user_email_confirmation" */ './screens/ProjectUserEmailConfirmation').then(module => ({ default: module.ProjectUserEmailConfirmation })));

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(e) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
    }

    render() {
        if (this.state.hasError) {
            return <Error mainLabel="mcrn.error.general" buttonLabel="mcrn.error.general.link.home" link="/" />
        }

        return <div className="position-relative">{this.props.children}</div>;
    }
}


const App = props => {

    useEffect(() => {
        if (!props.user.synchronized) {
            props.loadSettings();
        }
    });

    return (
        <ErrorBoundary>
            <Router history={history}>
                <Switch>
                    <Route exact path="/login/:email?" render={props => LoadingWithSuspense(Login, props)} />
                    <Route exact path="/signup/:token?/:email?" render={props => LoadingWithSuspense(Signup, props)} />
                    <Route exact path="/email/confirm/:token" render={props => LoadingWithSuspense(EmailConfirmation, props)} />
                    <Route exact path="/password/reset/request" render={props => LoadingWithSuspense(ResetPasswordRequest, props)} />
                    <Route exact path="/password/reset/:token" render={props => LoadingWithSuspense(ResetPassword, props)} />
                    <Route exact path="/project/user/invite/confirm/:token" render={props => LoadingWithSuspense(ProjectUserEmailConfirmation, props)} />
                    <Route exact path="/project/user/email/confirm/:token" render={props => LoadingWithSuspense(ProjectUserEmailConfirmation, props)} />
                    <PrivateRoute exact path="/" render={props => LoadingWithSuspense(Dashboard, props)}
                        exactRole={config.userRoles['user']} />
                    <PrivateRoute exact path="/dashboard" render={props => LoadingWithSuspense(Dashboard, props)}
                        exactRole={config.userRoles['user']} />
                    <PrivateRoute exact path="/profile" render={props => LoadingWithSuspense(Profile, props)} exactRole={config.userRoles['user']} />
                    <PrivateRoute exact path="/projects/:projectId/labels" render={props => LoadingWithSuspense(Labels, props)}
                        exactRole={config.userRoles['user']} />
                    <Route exact path="/404" render={props => LoadingWithSuspense(PageNotFound, props)} />
                    <Route render={props => LoadingWithSuspense(PageNotFound, props)} />
                </Switch>
            </Router>
        </ErrorBoundary>
    );
};

function mapStateToProps(state) {
    const { user } = state;
    return {
        user
    };
}

function mapDispatchToProps(dispatch) {
    return ({
        loadSettings: () => {
            dispatch(userActions.loadSettings())
        }
    })
}

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };
