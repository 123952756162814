import React, { useState } from 'react';
import { Popover, Transition } from '@headlessui/react'


export const Tooltip = props => {

    const [showTooltip, setShowTooltip] = useState(false);

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    return (
        <Popover className={'relative ' + (props.className ? props.className : '')}>
            {() => (
                <>
                    <div onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}>
                        <Popover.Button>
                            {props.btn}
                        </Popover.Button>
                    </div>
                    <Transition
                        show={showTooltip}
                    >
                        <Popover.Panel className="absolute z-10 bg-gray-200 pl-2 pr-2 pt-1 pb-1 rounded-md whitespace-nowrap">
                            {props.text}
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};
