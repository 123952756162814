import React from 'react';
import {
    LockClosedIcon, PencilSquareIcon, CheckCircleIcon, XCircleIcon, TrashIcon, DocumentTextIcon, PlusIcon, MagnifyingGlassIcon,
    ExclamationCircleIcon, ArrowPathIcon, LanguageIcon, CogIcon, ArrowUpTrayIcon
} from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


export const Button = props => {

    let Icon = false;
    let iconClasses = 'h-5 w-5 text-gray-300 group-hover:text-white';

    if (props.transparent) {
        iconClasses = 'h-5 w-5 text-gray-600 group-hover:text-gray-900';
    }

    switch (props.icon) {
        case 'lock':
            Icon = <LockClosedIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'pencil':
            Icon = <PencilSquareIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'check':
            Icon = <CheckCircleIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'cancel':
            Icon = <XCircleIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'trash':
            Icon = <TrashIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'document':
            Icon = <DocumentTextIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'plus':
            Icon = <PlusIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'search':
            Icon = <MagnifyingGlassIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'warning':
            Icon = <ExclamationCircleIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'refresh':
            Icon = <ArrowPathIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'settings':
            Icon = <CogIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'upload':
            Icon = <ArrowUpTrayIcon className={iconClasses} aria-hidden="true" />
            break;
        case 'translate':
            Icon = <LanguageIcon className={iconClasses} aria-hidden="true" />
            break;
        default:
            break;
    }

    let colors = 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white ';

    if (props.secondary) {
        colors = 'bg-gray-400 hover:bg-gray-500 focus:ring-gray-300 text-gray-900 ';
    } else if (props.danger) {
        colors = 'bg-red-400 hover:bg-red-500 focus:ring-red-300 text-white ';
    } else if (props.transparent && !props.noBorderOnTransparent) {
        colors = 'bg-transparent border border-gray-600 hover:border-gray-900 hover:bg-transparent text-gray-600 hover:text-gray-900 ';
    } else if (props.transparent) {
        colors = 'bg-transparent hover:bg-transparent text-gray-600 hover:text-gray-900 ';
    }

    return(
        <button
            type={props.type ? props.type : 'button'}
            {...(props.onClick ? {onClick: props.onClick} : {})}
            className={'group relative flex justify-center border ' +
            'border-transparent text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ' +
            (props.roundedLeft ? 'rounded-l-lg ' : (props.roundedRight ? 'rounded-r-lg ' : 'rounded-md ')) +
            'disabled:opacity-50 ' + (props.small ? 'py-1 px-3 ' : (props.extraSmall ? 'py-px px-1 ' : 'py-2 px-4 ')) +
            colors + (props.className || '')}
            disabled={props.disabled}
        >
            {Icon &&
                <span className={(props.label ? 'pr-1' : '')}>
                    {Icon}
                </span>
            }
            {props.label &&
                <FormattedMessage id={props.label}/>
            }
        </button>
    );
};

Button.propTypes = {
    icon: PropTypes.oneOf(['lock', 'pencil', 'check', 'cancel', 'trash', 'document', 'plus', 'search', 'translate', 'settings', 'upload']),
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    onClick: PropTypes.func,
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    secondary: PropTypes.bool,
    danger: PropTypes.bool,
    small: PropTypes.bool,
    extraSmall: PropTypes.bool,
    transparent: PropTypes.bool,
    noBorderOnTransparent: PropTypes.bool,
    roundedLeft: PropTypes.bool,
    roundedRight: PropTypes.bool
};
