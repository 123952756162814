import React, { Suspense } from 'react';
import { Text } from './';


export const LoadingWithSuspense = (Component, props) => {

    const SuspenseComponent = <Suspense fallback={
        <Text label="mcrn.loading" center />
    }>
        <Component {...props} match={props.match} />
    </Suspense>;

    return (
        <>{SuspenseComponent}</>
    );
};
