import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import './assets/index.scss';
import { store, IntlProvider } from './_library';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';


render(
    <StrictMode>
        <Provider store={store}>
            <IntlProvider>
                <App />
            </IntlProvider>
        </Provider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
